#message-input::placeholder {
  font-size: 12px;
  font-family: 'Raleway';
  font-weight: 600;
}

#phone-input::placeholder {
  font-size: 12px;
  font-family: 'Raleway';
  font-weight: 600;
}

#name-input::placeholder {
  font-size: 12px;
  font-family: 'Raleway';
  font-weight: 600;
}

#name-input,
#phone-input,
#message-input {
  color: #ffffff;
  font-size: 12px;
}

#name-input,
#phone-input {
  height: 50px;
}

.role {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 100;
  font-size: 10px;
}

@media (max-width: 575px) {
  #video {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 575px) {
  #home-heading {
    font-size: 30px !important;
  }
}

@media (max-width: 767px) {
  #start-card {
    display: none !important;
  }
}

@media (max-width: 767px) {
  #hiddendiv,
  #hiddenblur {
    display: block !important;
    margin-top: 15%;
  }
}

@media (max-width: 575px) {
  .navbar-brand > img {
    height: 50px;
  }
}

#hiddenblur {
  display: none;
  position: absolute;
  width: 70px;
  box-shadow: 4px 22px 150px 40px #c70064;
  z-index: -1;
}

.fit-cover {
  object-fit: cover;
}

.navigation-clean {
  background: #fff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #333;
  border-radius: 0;
  box-shadow: none;
  border: none;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .navigation-clean {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.navigation-clean .navbar-toggler {
  border-color: #ddd;
  color: #888;
}

.navigation-clean .navbar-toggler:hover,
.navigation-clean .navbar-toggler:focus {
  background: none;
}

.navigation-clean.navbar-light .navbar-nav .nav-link.active,
.navigation-clean.navbar-light .navbar-nav .nav-link.active:focus,
.navigation-clean.navbar-light .navbar-nav .nav-link.active:hover {
  color: #8f8f8f;
  box-shadow: none;
  background: none;
  pointer-events: none;
}

.navigation-clean.navbar .navbar-nav .nav-link {
  padding-left: 18px;
  padding-right: 18px;
}

.navigation-clean.navbar-light .navbar-nav .nav-link {
  color: #465765;
}

.navigation-clean.navbar-light .navbar-nav .nav-link:focus,
.navigation-clean.navbar-light .navbar-nav .nav-link:hover {
  color: #37434d !important;
  background-color: transparent;
}

.navigation-clean .navbar-nav > li > .dropdown-menu {
  margin-top: -5px;
  box-shadow: none;
  background-color: #fff;
  border-radius: 2px;
}

.navigation-clean .dropdown-menu .dropdown-item:focus,
.navigation-clean .dropdown-menu .dropdown-item {
  line-height: 2;
  color: #37434d;
}

.navigation-clean .dropdown-menu .dropdown-item:focus,
.navigation-clean .dropdown-menu .dropdown-item:hover {
  background: #eee;
  color: inherit;
}

@media (max-width: 575px) {
  .prod {
    padding-left: 15%;
    padding-right: 15%;
  }
  .productContainer {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  #contactusFloat {
    display: none;
  }
}

#contactusFloat {
  color: rgb(255, 255, 255);
  border: none;
  border-bottom: 2px solid rgb(255, 255, 255);
  background-color: transparent;
}
button {
  all: unset;
  cursor: pointer;
}

.prodCard {
  box-shadow: 0px 0px 0px #c70064;
  transition: box-shadow 0.25s ease-out;
  cursor: pointer;
}

.prodCard:hover {
  box-shadow: 1px 8px 20px #c70064;
  transition: box-shadow 0.25s ease-in;
}

.nav-active {
  background: #081019 !important;
  transition: all 0.25s ease-out;
}
